import React from "react";
import Page from "../layouts/Page";
import _ from "lodash";

export default class Mentions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      layout: (typeof window !== "undefined" && window.LAYOUT) || "grid"
    };
  }

  render() {
    return (
      <Page title="Mentions légales Akimed" description="Informations légales et politique d'utilisation des données personnelles.">
        <section className="section">
          <div className="container">
            <div class="columns">
              <div class="column is-8 is-offset-1">
                <div class="content is-medium">
                  <h1>Informations légales</h1>
                  <h3>Editeur</h3>
                  <p>
                    Akimed est une SASU au capital social de 1000 euros, immatriculée au Registre du Commerce 
                    et des Sociétés de Créteil sous le numéro 888 428 323, dont le siège social est situé au 
                    15 rue Thiebault 94220 Charenton-le-Pont.
                  </p>
                  <h3>Directeur de la publication</h3>
                  <p>Monsieur Anthony Dubois, Président de la SAS Akimed.</p>
                  <h3>Hébergeur</h3>
                  <p>Ce site web est hébergé par la société Netlify (www.netlify.com).</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div class="columns">
              <div class="column is-8 is-offset-1">
                <div class="content is-medium">
                  <h1>Politique d'utilisation des données personnelles</h1>
                  <h3>Identité du responsable du traitement</h3>
                  <p>
                    La société Akimed. <br />
                    « Site » désigne la site internet akimed.io.    
                  </p>
                  <h3>Coordonnées du contact CNIL d'Akimed</h3>
                  <p>Adresse électronique : contact@akimed.io</p>
                  <p>Adresse postale : Akimed, 15 rue Thiebault, 94220 Charenton-le-Pont</p>
                  <h3>Finalités du traitement des données à caractère personnel</h3>
                  <p>
                    Akimed collecte vos données à caractère personnel lors de votre navigation sur le Site, 
                    sur la base de votre consentement, soit de manière automatique, au moyen des cookies, 
                    soit via les formulaires de saisie d'informations présentés sur le Site. Vos données à caractère 
                    personnel sont donc collectées lorsque vous :
                  </p>
                  <ul>
                    <li>consultez les différentes pages du Site ;</li>
                    <li>vous inscrivez à notre newsletter ;</li>
                    <li>téléchargez un case study ou une étude ;</li>
                    <li>contactez le service clients.</li>
                  </ul>
                  <p>
                     En ce qui concerne les formulaires de saisie d'informations affichés sur le Site, 
                     les données à caractère personnel dont la communication est obligatoire pour la prise 
                     en compte de votre demande sont identifiées par un astérisque « * ».
                     Le défaut de communication d'une donnée personnelle identifiée comme obligatoire 
                     provoquera la non validation du formulaire en question. Akimed ne pourra donc 
                     pas donner suite à votre demande.
                  </p>
                  <p>Les finalités des traitements qu'Akimed effectue sont les suivantes :</p>
                  <ul>
                    <li>envoi de prospection commerciale ;</li>
                    <li>gestion des relations avec nos prospects ;</li>
                    <li>réalisation de statistiques d'audience.</li>
                  </ul>
                  
                  <h3>Cookies</h3>
                  <p>
                    Les données à caractère personnel qui sont obtenues automatiquement à l'occasion de 
                    votre connexion et votre navigation sur le Site, notamment l'adresse IP de votre terminal, 
                    et/ou par l'intermédiaire de fichiers informatiques stockés sur votre terminal, 
                    communément appelés « cookies », sont utilisées conformément aux modalités ci-dessous.
                  </p>
                  <h4>Les cookies de mesure d'audience et de statistiques</h4>
                  <p>
                    Ces cookies recueillent des informations sur la façon dont les visiteurs 
                    utilisent un site internet. Par exemple, le nombre de visites, les pages les plus visitées, etc.
                  </p>
                  <p>
                    L'adresse IP collectée permet d'identifier la ville depuis laquelle vous vous connectez, 
                    cependant, les deux derniers octets (contenant les informations sur la localisation précise) 
                    sont immédiatement anonymisés pour ne pas permettre d'identifier un individu via 
                    les données collectées.
                  </p>
                  <p>
                    Les données recueillies ne sont en aucun cas 
                    cédées à des tiers ni utilisées à d'autres fins que statistiques par Akimed.
                  </p>
                  <h4>Comment gérer les cookies ?</h4>
                  <p>
                    Plusieurs possibilités vous sont offertes pour gérer les cookies. 
                    Tout paramétrage que vous pouvez entreprendre sera susceptible de modifier 
                    votre navigation sur Internet et vos conditions d'accès à certains services 
                    nécessitant l'utilisation de Cookies.
                  </p>
                  <p>  
                    Vous pouvez faire le choix à tout moment d'exprimer et de modifier vos souhaits 
                    en matière de cookies, par les moyens décrits ci-dessous. La configuration de chaque navigateur est différente.
                    Elle est décrite dans le menu d'aide de votre navigateur.
                  </p>
                  <ul>
                    <li>Pour Chrome, <a href="https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en">suivez ce lien</a>.</li>
                    <li>Pour Internet Explorer, <a href="http://support.mozilla.org/fr/kb/cookies-informations-sites-enregistrent?redirectlocale=fr&redirectslug=G%E9rer+les+cookies">suivez ce lien</a>.</li>
                    <li>our Opera, <a href="http://windows.microsoft.com/fr-fr/internet-explorer/delete-manage-cookies">suivez ce lien</a>.</li>
                    <li>Pour Chrome, <a href="https://help.opera.com/en/latest/web-preferences/#cookies">suivez ce lien</a>.</li>
                  </ul>
                  <p>
                    Pour plus d'informations sur les cookies, vous pouvez consulter le site de la CNIL, 
                    <a href="http://www.cnil.fr/vos-droits/vos-traces/les-cookies/"> en cliquant sur ce lien</a>.
                  </p>
                  <h3>Destinataires des données</h3>
                  <p>
                    CLes données à caractère personnel collectées sur le Site sont réservées à l'usage d'Akimed.
                    Elles peuvent être communiquées à nos prestataires ou fournisseurs qui interviennent dans 
                    la gestion du Site (gestionnaire technique et hébergeur du Site, fournisseur du service 
                    de mesures d'audience notamment).
                  </p>
                  <h3>Durée de conservation</h3>
                  <p>
                    Akimed conserve les données à caractère personnel pendant une durée de trois ans 
                    à compter de la fin des relations commerciales avec le client et trois ans à compter du
                    dernier contact émanant avec le prospect.
                  </p>
                  <h3>Droit d'accès, de rectification, de suppression et de portabilité des données</h3>
                  <p>
                    Conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers 
                    et aux libertés, modifiée et au règlement européen « 2016/679/ UE du 27 Avril 2016 » relatif 
                    à la protection des personnes physiques à l'égard du traitement des données à caractère 
                    personnel et à la libre circulation de ces données, vous bénéficiez d'un droit d'accès, 
                    d'opposition, de rectification, de suppression et de portabilité de vos données personnelles. 
                    Vous pouvez exercer votre droit en vous adressant par e-mail à contact@akimed.io, 
                    ou par courrier à l'adresse suivante :
                  </p>
                  <p>Akimed, 15 rue Thiebault, 94220 Charenton-le-Pont, France</p>
                  <p>
                    Votre demande devra indiquer votre nom et prénom, adresse e-mail ou postale,
                    être signée et accompagnée d'un justificatif d'identité en cours de validité.
                  </p>
                  <p>
                    Vous pouvez également vous opposer à l'envoi des informations commerciales en cliquant
                    sur le lien de désabonnement présent dans chaque envoi.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Page>
    );
  }
}
